var OrganizationService = /** @class */ (function () {
    function OrganizationService() {
    }
    OrganizationService.confirmPolicy = function () {
        sessionStorage.setItem(this.tokenKeyregister, "confirmed");
    };
    OrganizationService.checkConfirmPolicy = function () {
        return sessionStorage.getItem(this.tokenKeyregister) ? true : false;
    };
    OrganizationService.tokenKeyregister = "organizationCheckedPolicy";
    return OrganizationService;
}());
export default OrganizationService;
