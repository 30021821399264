<template>
    <div>
        <div class="login">
            <div class="login-page">
                <div class="login_title">
                    <div class="login_title__app-name">マナビDX Quest</div>
                    <p class="login_title__description">
                        Project based Learning - DX人材と企業の協働マッチング
                    </p>
                </div>
                <div class="login-page__container">
                    <div class="login-page__container-title">
                        ログイン
                    </div>
                    <p class="login-page__container-sub_title">
                        会員登録済みの方は下記より、ログインしてください。
                    </p>
                    <div class="login-page__container__btnGroup">
                        <button
                            class="login-page__container__btnGroup-engineerLogin"
                            @click="userLogin"
                        >
                            {{ state.aLang.login_user_text }}
                        </button>
                        <button
                            class="login-page__container__btnGroup-comLogin"
                            @click="gotoOrganizationLogin"
                        >
                            {{ state.aLang.login_organization_text }}
                        </button>
                    </div>
                </div>
                <!-- 
                <div class="login-page__container">
                    <div class="login-page__container-title">
                        会員登録がお済みでない方
                    </div>
                    <p class="login-page__container-sub_title">
                        まだ会員登録がお済みでない方は下記よりご登録ください。
                    </p>
                    <div class="login-page__container__btnGroup register">
                        2022年SSO改修　来年度は新しくユーザーを作成する可能性のため残しておく <button
                            class="login-page__container__btnGroup-engineerLogin"
                            @click="gotoUserRegister"
                        >
                               {{ state.aLang.register_user_text }} <br />
                        </button>
                        <button
                            class="login-page__container__btnGroup-comLogin"
                            @click="gotoOrganizationRegister"
                        >
                          {{ state.aLang.register_organization_text }}<br/>
                        </button>
                    </div>
                </div>
                -->
            </div>
        </div>
      <Alert v-if="state.showPopupAlert" content="トークン切れになりました。再度ログインしてからご使用ください" @close="closePopup"/>
      <Alert v-if="state.showSlackAuthenticationError"
             content="エラー：403 Forbidden<br>- このページへのアクセスは許可されておりません。<br>- DXQuest受講生のアカウントでログインしてください。<br>- 問題が解決しない場合は事務局へお問い合わせください。"
             @close="closeSlackAuthErrorPopup"/>
    </div>
</template>

<script lang="ts" src="@/presentation/views/login/Login.ts" scoped></script>

<style lang="scss">
@import "@/presentation/views/login/Login.scss";
</style>
