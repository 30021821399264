import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import routePath from "@/commons/RoutePath";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import AuthInteractor from "@/domain/usecases/AuthInteractor";
import DIContainer from "@/core/DIContainer";
import MyConfig from "@/_config";
import OrganizationService from "@/services/OrganizationService";
import PolicyPopup from "@/presentation/components/modals/organization-policy/OganizationPolicy.vue";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import LanguageUtil from '@/commons/LanguageUtil';
export default defineComponent({
    name: "LoginPage",
    components: { Breadcrumb: Breadcrumb, PolicyPopup: PolicyPopup, Alert: Alert },
    setup: function () {
        var router = useRouter();
        var store = useStore();
        var config = MyConfig;
        var state = reactive({
            listBreadcrumb: [
                {
                    route: "",
                    name: "TOP"
                }
            ],
            showPolicyPopup: false,
            showPopupAlert: false,
            showSlackAuthenticationError: false,
            aLang: LanguageUtil.getInstance().lang
        });
        var authInteractor = DIContainer.instance.get(AuthInteractor);
        function gotoOrganizationLogin() {
            router.push(routePath.ORGANIZATION_LOGIN);
        }
        function gotoOrganizationRegister() {
            OrganizationService.confirmPolicy();
            store.commit("setOrganizationResgisterData", {});
            router.push(routePath.ORGANIZATION_REGISTER);
        }
        function userLogin() {
            var nonePathCallback = [
                "/user/register/profile",
                "/user/register/confirm",
                "/user/register/completed",
                "/organization/login",
                "/organization/register/profile",
                "/organization/register/detail_profile",
                "/organization/register/confirm",
                "/organization/register/send_mail",
                "/organization/register/completed"
            ];
            var callBackUrl = !store.state.callBackUrl || nonePathCallback.includes(store.state.callBackUrl)
                ? routePath.USER_DASHBOARD
                : store.state.callBackUrl;
            window.location.href = MyConfig.loginUrl + "?spurl=" + encodeURIComponent(callBackUrl);
        }
        function gotoUserRegister() {
            var callBackUrl = !store.state.callBackUrl
                ? "/"
                : store.state.callBackUrl;
            window.location.href = MyConfig.registerUrl + "?spurl=" + encodeURIComponent(callBackUrl);
        }
        function closePopup() {
            state.showPopupAlert = false;
            sessionStorage.removeItem("tokenExpired");
        }
        function closeSlackAuthErrorPopup() {
            state.showSlackAuthenticationError = false;
        }
        function getSlackAuthCookie() {
            var cookiesArray = document.cookie.split(';');
            for (var _i = 0, cookiesArray_1 = cookiesArray; _i < cookiesArray_1.length; _i++) {
                var cookie = cookiesArray_1[_i];
                var cookieArray = cookie.split('=');
                if (cookieArray[0].includes('user_is_not_authenticated')) {
                    // クッキー削除
                    document.cookie = cookie + ";max-age=0";
                    return true;
                }
            }
            return false;
        }
        onMounted(function () {
            store.commit("setHeaderMode", "default");
            var checkToken = sessionStorage.getItem("tokenExpired");
            state.showPopupAlert = JSON.parse(JSON.stringify(checkToken)) ? true : false;
            state.showSlackAuthenticationError = Boolean(getSlackAuthCookie());
        });
        return {
            state: state,
            config: config,
            gotoOrganizationLogin: gotoOrganizationLogin,
            gotoOrganizationRegister: gotoOrganizationRegister,
            userLogin: userLogin,
            gotoUserRegister: gotoUserRegister,
            closePopup: closePopup,
            closeSlackAuthErrorPopup: closeSlackAuthErrorPopup
        };
    }
});
