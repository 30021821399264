<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-body">
                        <slot name="body">
                            <div class="policy">
                                <div class="policy__content">
<pre>
<p class="title">マナビDX Questサイト利用規約</p>

本規約は、株式会社SIGNATE（以下、「当社」といいます。）が本サイト（以下に定義します。）において提供するサービス（以下に定義します。）をご利用になる会員（以下に定義します。）その他の方に共通して適用されます。 本規約を熟読し、ご同意のうえ、本サービスをご利用ください。


第１条（定義）
本規約において、次の各用語の定義はそれぞれ次に定めるとおりとします。

(１)「本サービス」とは、当社が本サイトにおいて提供するサービス（利用のためのソフトウェア又はコンテンツの提供を含みます。）をいいます。
(２)「本サイト」とは、当社が管理するウェブサイト「マナビDX Quest（ https://aiquest.signate.jp ）」のことをいいます。
(３)「利用者」とは、本サイトにアクセスして本サービスの全部又は一部を利用するすべての方をいいます。 利用者には、会員と会員以外の方が含まれます。
(４)「個人情報」とは、個人情報の保護に関する法律に定める個人情報をいいます。
(５)「会員登録」とは、入会時に所定の必要事項を本サイトにおいて登録することをいいます。
(６)「入会申請」とは、会員登録により入会を申請することをいいます。
(７)「会員」とは、利用者のうち、当社が入会申請を承認した方をいいます。
(８)「登録情報」とは、入会時に会員が本サイトにおいて登録した情報をいいます。登録情報には、メールアドレスやパスワードが含まれます。
(９)「公開情報」とは、利用者が本サイトにおいて公開した情報（例　ユーザネーム、企業情報、プロフィール、プロジェクト、提供サービス等）をいいます。
(１０)「投稿」とは、利用者が情報やデータを本サイトにおいて投稿、掲載、開示、提供又は送信し、他の利用者や当社が閲覧可能な状態にするする行為をいいます。


第２条（会員登録と本サービスの利用）
１．会員は、会員登録時に、本規約及び別途定める「個人情報の取扱いについて」の内容すべてに同意するものとします。 会員以外の利用者が、会員登録をせずに利用できる本サービスを実際にご利用いただいた時点で、本規約にご同意いただいたものとみなします。
２．会員資格は会員１人につき１資格のみであり、同一会員が複数の会員資格を保有することはできません。
３．当社は、入会申請をする者へ何ら理由を開示することなく、その完全な裁量により、当該入会申請を不承認とすることができるものとします。
４．入会申請を行うことができるのは、その会員となる本人（法人の場合には対外的な契約権限を有する者）に限るものとし、代理人による入会申請・会員登録は認められないものとします。
５．会員となる本人は、日本語若しくは英語又はその両方を理解し、どちらか一方若しくは両方の読み書きができ、かつ免許証・パスポート・その他公的機関の発行した身分証明書を有している必要があります。
６．会員となる本人が未成年の場合、親権者その他の法定代理人の包括的な同意を得ている必要があります。
７．会員となる本人が法定代理人の同意がないにもかかわらず同意があると偽り又は年齢について成年と偽って本サービスを利用した場合、その他行為能力者であることを信じさせるために詐術を用いた場合、本サービスに関する一切の法律行為を取り消すことはできません。
８．本規約の同意時に未成年であった会員が成年に達した後に本サービスを利用した場合、当該会員は本サービスに関して行った一切の法律行為を追認したものとみなされます。

第３条（サービス内容の保証および変更）
１．当社は、提供するサービスについて、瑕疵やバグがないことは保証しておりません。
２．当社は、必要に応じて利用者に何ら通知することなく、本サービスに新しい機能やサービスを追加変更したり、提供を停止したり中止したりする場合があります。
３．当社は、１ヵ月の予告期間をもって利用者に通知の上、本サービスの長期的な中断もしくは終了を行うことができます。
４．当社は、本条に基づき、当社が行った措置により生じた結果及び損害について、一切の責任を負わないものとします。

第４条（サービスの利用制限）
１．当社は、サービス及びコンテンツの利用を利用者のうち会員に限定したり、一定の年齢以上の方に限定したり、当社が定める本人確認などの手続きを経て一定の条件を満たした利用者のみに限定したりする等、利用に際して条件を付すことができるものとします。
２．利用者は、自己が反社会的勢力でないことを表明し保証するものとします。
３．当社は、反社会的勢力や、サービスを悪用したり、第三者に迷惑をかけたりするような利用者に対して、何ら理由を開示することなく、その完全な裁量により、利用をお断りすることができるものとします。

第５条（会員その他の利用者の責任）
１．利用者は、法令及び当社が案内する方法に従い、本サービスを利用するものとします。
２．入会申請を行う利用者は、自らの意思及び責任をもって入会申請するものとし、会員登録の際に入力した登録情報に対し、利用者自らがその内容の正確性、真実性、完全性につき責任を負い、登録情報が不正確、虚偽又は不完全であったために当該利用者や他の利用者が被った一切の不利益及び損害に関して、当社は責任を負わないものとします。 尚、会員は、入会後、会員登録の際に入力した登録情報及びその他の情報をいつでも自ら修正、削除、追加することができるものとします。
３．登録情報の内容が最新となるようご自身で適宜修正することは会員の義務となります。
４．会員は、メールアドレスやパスワードを含んだ登録情報について、自己の責任の下で適切に管理し、特にメールアドレス及びパスワードの漏えいを防止する処置について、責任をもって講じるものとします。
５．当社は、会員本人の登録情報を用いた認証後に本サービスの利用があった場合、当該登録情報に係る会員本人による利用として扱うことができ、当該利用が会員本人ではなく、他の者による行為であったとしても、それにより生じた結果並びにそれに伴う一切の責任については、当該会員に帰属するものとし、当社は一切の責任を負わないものとします。
６．当社は、登録情報の全部又は一部に誤りがあったために第三者からクレーム又は退会依頼があった場合事前の通知をすることなく、当該登録情報の全部又は一部を削除できるものとし、会員は、これを承諾するものとします。
７．会員は、本サービスの利用を通じて他の利用者を含む第三者に対し不利益・損害を与えた場合、自己の費用と責任においてこれを解決するものとし、当社は一切の責任を負わないものとします。
８．会員は、本サービス上のすべての手続きに関して当社がする連絡・要請に対し、当社からの送信日から７日間以内に応答するものとします。
９．会員は、会員としての地位その他本規約に基づき利用者が認められる一切の権利を、第三者に利用許諾、譲渡又は貸与することはできないものとします。

第６条（個人情報等の取り扱い）
１.当社は、利用者のプライバシーを尊重し、個人情報の管理に細心の注意を払い、別途定める「個人情報の取扱いについて」に従って、利用者の個人情報を取り扱うものとします。
２.当社は、利用者からお預かりする登録情報について、以下に定める目的の為に利用します。
(１)本サービスの運営・管理のため
(２)当社と利用者間での必要な連絡を行うため
(３)当社及び当社が委託を受けた顧客企業等からの連絡・メールマガジン・各種お知らせ等の配信・送付のため
(４)利用者の同意・申し込みに基づく、顧客企業等への個人情報の提供のため
(５)本サービスの調査・改善および新たなサービス等を検討するため
(６)当社と利用者の間で同意した目的のため
(７)その他、別途定める「個人情報の取扱いについて」に記載する目的のため


３.当社は、利用者の意見やアンケートの回答内容、本サービスの利用記録について、個人が特定できない情報として加工を施した上で、当社発行のパンフレットやWeb上の情報媒体等に、当該利用者に事前の同意を得ることなく掲載及び取得・利用・提供・管理できるものとし、利用者はこれに同意するものとします。
４.プロジェクト・提供サービスの内容や結果及び会員の成績などの公開情報については、当該利用者に事前の同意を得ることなく、当社の営業・販売促進活動に利用し、広告・宣伝等を目的として本サイトに掲載し、当社及び当社が許可した第三者のメディアへ掲載・利用することができるものとします。
第７条（会員の退会・除名）
１.会員が本サービスの退会を希望する場合、当社所定の手続を完了することにより、原則として、いつでも自由に本サービスからの退会ができるものとします。 ただし、本サービスにおける何らかの未了の手続きがある場合、その完了まで本サービスからの退会はできないものとします。
２.当社は、利用者が本規約に違反していると当社が判断した場合、もしくは、当社に不利益を与える行為、又は名誉、信用を損なうような行為があった時、当該利用者に事前の通知をすることなく、投稿を削除し、サービスの利用を一時的若しくは永続的に停止させることができるものとし、利用者はこれを予め承諾するものとします。

第８条（利用者の禁止行為）
１．利用者は本サービスを利用して次に示す行為をしないものとします。
(１)虚偽の情報を登録又は投稿する行為
(２)他人名義での登録又は投稿する行為
(３)他の利用者、第三者又は当社の知的所有権、肖像権又はパブリシティ権等の正当な権利を侵害する行為、又は侵害するおそれのある行為
(４)他の利用者、第三者又は当社の財産、信用、名誉又はプライバシー等を侵害する行為、犯罪行為もしくは公序良俗に反する行為
(５)他の利用者、第三者又は当社を誹謗中傷、侮辱する行為、又は他の利用者、第三者又は当社への不当な差別を助長する行為
(６)他の利用者、第三者又は当社に不利益又は損害を与える行為
(７)営業又は営利を目的とした（情報提供活動等を含む）行為
(８)選挙運動又はこれに類似する行為及び公職選挙法に抵触する行為、又はそのおそれのある行為
(９)無限連鎖講（ネズミ講）を開設、又はこれを勧誘する行為
(１０)他の利用者を含む第三者の設備やインターネット接続サービス用の設備の利用又は運営に支障を与える行為、もしくは与えるおそれのある行為
(１１)他の利用者を含む第三者に対し、本人の承認なしに、広告、宣伝又は勧誘の電子メールその他のメッセージを送信する行為、社会通念上第三者に嫌悪感を抱かせる、もしくはそのおそれのある電子メールその他のメッセージを送信する行為
(１２)猥褻、児童ポルノ又は児童虐待、人の殺害現場、動物を殺傷・虐待現場、その他社会通念上第三者に著しく嫌悪感を抱かせるに相当する画像、映像、音声もしくは文書等を不特定多数の者に対して送信又は表示する行為、又はこれらを収録した媒体を販売する行為、もしくはその送信、表示、販売を想起させる広告を送信又は表示する行為
(１３)犯罪行為、不法行為を含む違法行為（詐欺、児童買春、児童ポルノの提供、預貯金口座及び携帯電話の違法売買、薬物犯罪、規制薬物の濫用、拳銃の譲渡、爆発物の不正な製造、公文書偽造、殺人、脅迫等）、もしくはそれらに結びつくおそれのある行為、その他公序良俗に反する行為
(１４)他の利用者を含む第三者に対し、違法な賭博・ギャンブルを行わせる、違法な賭博・ギャンブルへの参加を勧誘する行為
(１５)他の利用者を含む第三者に対し、自殺を誘引もしくは勧誘する、第三者に危害の及ぶおそれのある自殺手段等を紹介する行為
(１６)反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団関係企業、総会屋、社会標ぼうゴロ、政治活動標ぼうゴロ、特殊知能暴力集団等を含むが、これに限られない。）等に属する行為、もしくは該当する行為、反社会的勢力に利益を与えもしくは利用する等不適切な関係を持つ行為、又はそれらのおそれのある行為
(１７)他の利用者、第三者又は当社の名称を騙り、なりすます行為
(１８)未成年に対し悪影響があると当社が判断する行為
(１９)本サービスを通じて入手した情報を複製、販売、出版その他私的利用の範囲を超えて使用する等、本サービスに関連した私的利用の範囲を超えて行う行為
(２０)本サービスの運営を妨げる行為、もしくは当社又は本サービスの信用を毀損するような行為、又はそのおそれがある行為
(２１)コンピュータウィルスその他の有害なコンピュータープログラムを含む情報等を送信又は掲載する行為
(２２)他の利用者を含む第三者に、利用者としての地位、本規約に基づき利用者に認められる一切の権利を、利用承諾、貸与、又は譲渡する行為
(２３)その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する態様又は目的でインターネットメディア又はアプリケーション上にURLを掲載する行為
(２４)当社に帰属する一切の著作権（著作権法27条及び28条に規定する権利を含みます。）、特許権、商標権その他の知的財産権（登録等を出願する権利を含みます。）を侵害する、本サービスの逆アセンブル、逆コンパイル、リバースエンジニアリング、複製、変更、改変、クローリング等の行為
(２５)日本国又はご利用の際に利用者が所在する国・地域の法令及び本規約に違反する行為
(２６)その他、当社が不適切と判断する行為


２．利用者は、他の利用者の登録情報又は投稿が前項に定めた禁止事項に該当することを発見した場合、当社に通報することができます。 この場合、当社は通報された登録情報や投稿内容を閲覧でき、当該利用者や当該他の利用者にあらかじめ通知することなく、しかるべき措置を実施することができるものとします。
第９条（責任・保証の否認）
当社は、故意又は重大な過失がない限り、本サービスの利用により利用者又は第三者が被った損害のすべて（精神的苦痛、又はその他の金銭的損失を含む一切の不利益）に対し、いかなる責任も負わないものとします。 また、当社は、いかなる場合も、本サービスの利用により利用者又は第三者が被った直接かつ現実の損害に限定して賠償するものとし、特別損害、派生的若しくは付随的損害、間接的損害又は結果的損害、逸失利益、逸失収入などを賠償する責任は負わないものとします。

第１０条（不可抗力）
当社は、社会通念上通常講じるべきコンピュータウィルス対策では防止できないウィルスやその他の攻撃による被害、天変地異、ネットワーク上の障害、その他当社の責によらない事由によって本サービスの提供が遅延し、又は困難もしくは不能となった場合であっても、これによって利用者に発生した結果及び損害について、一切の責任を負わないものとします。

第１１条（損害賠償）
利用者が本規約その他の当社の規約に違反し、当社又は第三者に損害を与えた場合は、当該利用者はその損害を賠償するものとします。 また、利用者が本規約その他の当社の規約に違反したことにより第三者と当社の間で紛争が生じ、その他第三者が当社に対して請求を行った場合、当該利用者は当社が被る一切の損害、損失、費用（弁護士費用を含むがこれに限定されない。）、特別損害、派生的若しくは付随的損害、間接的損害又は結果的損害、逸失利益、逸失収入などを補償するものとします。 尚、利用者が本規約に違反した場合において当社が当該違反への対応のために行う措置（本規約その他の当社の規約に基づき行う措置を含みますが、これに限定されません。）によって、当該利用者に損害が生じたとしても、当社は一切の責任を負わないものとします。

第１２条（広告掲載について）
当社は、当社又は当社に掲載依頼をした第三者の広告を掲載し、利用者が閲覧可能な状態に置くことができるものとします。

第１３条（個別サービス規約）
特定の本サービスにおいて、本規約の他に、当社の規約において当該本サービス固有の利用条件が設けられている場合があります。 本規約と当該本サービス固有の利用条件が異なっている場合には、特段の定めがない限り、当該本サービスの利用に関しては当該本サービス固有の利用条件が本規約に優先して適用されるものとします。

第１４条（本契約上の地位の譲渡等）
当社は、当社が本サービスにかかる事業を第三者に譲渡した場合には、当該事業譲渡に伴い当社の本規約上の地位、権利義務及び利用者から受領した個人情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき本条において予め同意したものとします。 なお、本条に定める事業譲渡には、会社法に規定された事業譲渡のみならず、会社分割その他事業が包括承継されるあらゆる場合を含むものとします。

第１５条（当社への連絡・通知）
利用者が当社への連絡を希望される場合には、当社が設けた問合せページ又は当社が指定するメールアドレス宛のメールによって行っていただくものとします。 当社は、利用者からのお問い合わせに対する回答を原則としてメールのみで行います。

第１６条（準拠法等）
１．本規約その他の当社の規約は、日本法に準拠し、日本法に従って解釈されます。
２．利用者及び当社は、本規約その他の当社の規約に関して紛争が生じた場合、訴額に応じて、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とすることに合意するものとします。

第１７条（本規約の変更）
当社は、利用者の了承を得ることなく、本規約その他の当社の規約を随時変更、追加、削除することができます。

2020年12月23日　制定
</pre>
                                </div>
                            </div>
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <button
                                @click="$emit('accept')"
                                class="button__accept sweep-to-top"
                            >
                                同意する
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="@/presentation/components/modals/organization-policy/OganizationPolicy.ts" />
<link type="scss" scoped src="@/presentation/components/modals/organization-policy/OganizationPolicy.scss" />
